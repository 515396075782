import { createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1bc8b68c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-layout" }
const _hoisted_2 = {
  class: "layout-content",
  ref: "refScrollBlock"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode($setup["MigrateBanner"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode($setup["SideBar"], {
        class: _normalizeClass(["sidebar", { 'hide-mobile': $setup.hideMobileSidebar, 'no-active': !$setup.isActiveMainSubs && !$setup.isCoach }])
      }, null, 8, ["class"]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_view, null, {
          default: _withCtx(({ Component }) => [
            _createVNode(_Transition, {
              name: "fade",
              mode: "out-in"
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                  "bottom-arrived": $setup.arrivedState.bottom,
                  "top-arrived": $setup.arrivedState.top,
                  modelValue: $setup.y,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.y) = $event))
                }, null, 8, ["bottom-arrived", "top-arrived", "modelValue"]))
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        })
      ], 512)
    ])
  ]))
}